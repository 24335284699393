import { IoIosCheckmarkCircle, IoIosCloseCircle, IoIosWarning } from "react-icons/io";

function Alert({ Type, Title, Description, onClose, Modal, Image }) {
    const alertContent = (
        <div
            className={`Alert_MainDiv ${Type === 'error'
                    ? 'Alert_Error'
                    : Type === 'warning'
                        ? 'Alert_Warning'
                        : Type === 'success'
                            ? 'Alert_Success'
                            : ''
                }`}
        >
            <button onClick={onClose} className="Alert_MainDiv_Btn">
                X
            </button>
            <div className="Alert_MainDiv_Icon">
                {Type === 'error' ? (
                    <IoIosCloseCircle />
                ) : Type === 'warning' ? (
                    <IoIosWarning />
                ) : Type === 'success' ? (
                    <IoIosCheckmarkCircle />
                ) : (
                    ''
                )}
            </div>
            <div className="Alert_MainDiv_Title">{Title}</div>
            <div className="Alert_MainDiv_Description">
                {Description.split('\n').map((line, index) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ))}
            </div>
            {Image && (
                <div className="Alert_MainDiv_Image">
                    <img src={Image} alt="Alert visual" />
                </div>
            )}
        </div>
    );

    return Modal ? (
        <div className="confirm_modal_overlay zindex1000">
            {alertContent}
        </div>
    ) : (
        alertContent
    );
}

export default Alert;

import { useState } from "react";
import ConfirmModal from "../../../../elements/newElements/confirm_modal";

export default function DownloadCard({ Icon, Title, Version, Date, Size, Type, AddClassname, onDownload, onDownloadDescription }) {

    const [viewConfirmDownload, setViewConfirmDownload] = useState(false)

    return (
        <>
            <div className={`download_card_upper ${AddClassname}`}>
                <div className="download_card_inner_up">
                    <div className="download_card_icon"><Icon /></div>
                    <div className="download_card_title">{Title}</div>
                </div>
                <div className="download_card">
                    <div className="download_card_version"><strong>Versione:</strong> {Version}</div>
                    <div className="download_card_date"><strong>Data:</strong> {Date}</div>
                    <div className="download_card_size"><strong>Dimensione:</strong> {Size}</div>
                    <div className="download_card_type"><strong>Tipo:</strong> {Type}</div>
                    <button
                        className="download_btn_download"
                        onClick={() => setViewConfirmDownload(true)}>
                        Download
                    </button>
                </div>
            </div>

            {viewConfirmDownload && <ConfirmModal
             Title="Conferma" 
             Description={onDownloadDescription}
             onConfirm={() => {onDownload(); setViewConfirmDownload(false)}}
             onCancel={() => setViewConfirmDownload(false)}
             />}
        </>
    );
};
import DownloadCard from "./download_card";
import { FaTools } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";


export default function Downloads({ navOpened }) {
    return (
        <>
            <div className={`Ticket_InCorso_MainDiv margin-top50 padding-bottom50px ${navOpened ? "padding-leftTickets" : ""}`}>
                <DownloadCard 
                    Icon={FaTools}
                    Title="Masp Tools (BETA)"
                    Version="1.0.0"
                    Date="29/01/2025"
                    Size="318 Mb"
                    Type="Software"
                    onDownload={() => window.open("https://drive.google.com/uc?export=download&id=1VsnOWu0jh0lnAjGYREY8p-ZW_IL_F5y3", "_blank")}
                    onDownloadDescription={`Sei sicuro di procedere con il download? Verrai reinderizzato a Google Drive, l'applicativo non contiene virus dunque procedere con 'Scarica comunque'`}
                />
                 <DownloadCard 
                    Icon={IoDocument}
                    Title="Masp Tools Documentazione Non Extended"
                    Version="1.0.0"
                    Date="31/01/2025"
                    Size="366 Kb"
                    Type="Documentazione"
                    AddClassname="margin-top50"
                    onDownload={() => window.open("https://drive.google.com/file/d/1XUQ10G65fDLVT5-GCic5ssQgyaypdzNq/view?usp=drive_link", "_blank")}
                    onDownloadDescription={`Sei sicuro di procedere con il download? Verrai reinderizzato a Google Drive.`}
                />
            </div>
        </>
    );
};
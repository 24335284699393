import { useTranslation } from 'react-i18next';
import HomeSection from '../../elements/homeSection';
import { useEffect, useState } from 'react';

export default function PickingVersioniDesc() {
  const { t } = useTranslation("picking");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log("windowWidth",windowWidth)

  const imageWidthLight = windowWidth <= 1200 ? "50%" : "70%";
  const imageWidthImage = windowWidth <= 1200 ? "50%" : "70%";

  console.log("imageWidthLight",imageWidthLight)
  console.log("imageWidthImage",imageWidthImage)

  const imagesListLight = [
    { src: "/images/picking/pickinglight.png", alt: "Pick To Light", width: imageWidthLight }
  ];
  const imagesListImage = [
    { src: "/images/picking/pickingrovesciatoOLD.png", alt: "Pick To Image", width: imageWidthImage }
  ];

    return (
        <>
            <div className='width100 bgwhite'>
                <HomeSection
                    title={t("versions.light_header")}
                    imgSrcList={imagesListLight}
                    putBackground={true}
                    descriptionNonList={t("versions.light_description")}
                    backgroundColor={"white"}
                    flip={1}
                    href="picktolight"
                />
                <HomeSection
                    title={t("versions.image_header")}
                    imgSrcList={imagesListImage}
                    putBackground={true}
                    descriptionNonList={t("versions.image_description")}
                    backgroundColor={'rgb(245,245,245)'}
                    flip={0}
                    href="picktoimage"
                />
            </div>
        </>
    );
};
import styled from 'styled-components';
import ItodoImage from './itodo-img';

const HalfBannerMainDiv = styled.div`
  background-color: var(--primary);
  width: 70%;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-height: 500px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 30px -10px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    max-height: 100%;
    justify-content: center;
    align-items:right;
    padding: 30px;
  }
`;

const HalfBannerImgdiv = styled.div`
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 1100px) {
        position: absolute;
        right:0;
        bottom:0;
        width: 40%;
    }

    @media screen and (max-width: 900px) {
        position: absolute;
        right: -20%;
        bottom:0;
        width: 60%;
    }

    
    @media screen and (max-width: 500px) {
        position: absolute;
        right: -20%;
        bottom:0;
        width: 100%;
    }
`;

const HalfBannerContentdiv = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding-left: 10px;

    @media screen and (max-width: 1100px) {
        width: 80%;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;


const HalfBannerTitle = styled.div`
    width: 90%;
    font-size: 50px;
    font-weight: 600;
    padding-top: 30px;
    color: white;
    @media screen and (max-width: 1100px) {
        width: 100%;
    }
    @media screen and (max-width: 560px) {
        font-size: 40px;
    }
    @media screen and (max-width: 460px) {
        font-size: 30px;
    }
`;

const HalfBannerDesc = styled.div`
    width: 90%;
    font-size: var(--fontSizePar);
    font-weight: 400;
    padding-top: 30px;
    color: white;
    @media screen and (max-width: 1100px) {
        width: 100%;
    }
`;


const HalfBannerFlow = styled.div`
    width: 100%;
    height: 40vh;
    background-color: rgb(248, 248, 248);
    position:absolute;
    top:50%;
    z-index: 0;
`;



const HalfBanner = ({ imgSrc, titleWhite, titleThird, desc, btnText, btnHref }) => {
    return (
        <>
            <div className='width100 perflex padding-top70px position-relative'>
            <HalfBannerFlow></HalfBannerFlow>
                <HalfBannerMainDiv>
                    <HalfBannerImgdiv className='section-full wow fadeInLeft' data-wow-duration="1500ms">
                        <ItodoImage className="width100" src={imgSrc}></ItodoImage>
                    </HalfBannerImgdiv>

                    <HalfBannerContentdiv className='section-full wow fadeInRight' data-wow-duration="1500ms">
                        <HalfBannerTitle className='section-full wow fadeInRight' data-wow-delay="0ms">
                            {titleWhite}
                        </HalfBannerTitle>
                        <HalfBannerTitle style={{ color: 'var(--third)' }}  className='section-full wow fadeInRight' data-wow-delay="300ms">
                            {titleThird}
                        </HalfBannerTitle>
                        <HalfBannerDesc>
                            {desc}
                        </HalfBannerDesc>
                        <div>
                            <a href={btnHref}><button id="button-third">{btnText}</button></a>
                        </div>
                    </HalfBannerContentdiv>
                </HalfBannerMainDiv>
            </div>
        </>
    )
};

export default HalfBanner;
import { useTranslation } from "react-i18next";
import { bannerData } from "../../../globals/banner";
import BenefitCard from "../../elements/BenefitCard";
import FaqCardViewer from "../../elements/FaqCardViewer";
import Banner from "../../elements/common/bannerautoind";
import CaratteristicheMASP from "./caratteristicheMasp";
import IntroMasp from "./intromasp";
import MaspNewBanner from "./maspNewBanner";
import MaspPrequel from "./maspprequel";
import DemoMasp from "./demoMasp";
import HalfBanner from "../../elements/HalfBanner";
import SectionCounter from "./SectionCounter";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function MaspCaratteristiche() {
    const location = useLocation();
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const { t } = useTranslation("masp_caratteristiche");

    const DataList = t('advantages.li_data', { returnObjects: true });

    if (!Array.isArray(DataList)) {
        console.error("Expected 'DataList' to be an array but got:", DataList);
        return null;
    }


    return (
        <>

            {false && <DemoMasp></DemoMasp>}
            <MaspNewBanner></MaspNewBanner>
            {false && <Banner _data={bannerData.masp_caratteristiche} />}


            <div className="bg-black">
                {<MaspPrequel></MaspPrequel>}
            </div>
            <div className="width100 perflex bg-title">
                <div className="width80 perflex">
                    <BenefitCard title={t("advantages.header")} dataList={DataList}></BenefitCard>
                </div>
            </div>

            <div className="section-full p-t100 p-b70 mobile-page-padding bg-gray-light perflex">
                <div className="width90 perflex ">
                    {<CaratteristicheMASP></CaratteristicheMASP>}
                </div>
            </div>

            {true && <FaqCardViewer></FaqCardViewer>}
            
            <HalfBanner
                imgSrc={t("halfbanner.imgSrc")}
                titleWhite={t("halfbanner.titleWhite")}
                titleThird={t("halfbanner.titleThird")}
                desc={t("halfbanner.desc")}
                btnText={t("halfbanner.btnText")}
                btnHref={t("halfbanner.btnHref")}
            />
            <div className="zindex1000 bggrey">
                <SectionCounter></SectionCounter>
            </div>
            <div className="section-full mobile-page-padding containerjobheadermasp">
                {/*<div className="container">*/}
                <IntroMasp></IntroMasp>
                {/*</div>*/}
            </div>

        </>
    )
}
export default MaspCaratteristiche;
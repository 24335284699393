import HomeSection from "../../elements/homeSection"
import { useTranslation } from 'react-i18next';

export default function PickingRadial() {

    
    const { t } = useTranslation("picking");
    const tracciabilitaDataIntro = t('radialData', { returnObjects: true });

    console.log(tracciabilitaDataIntro);

    if (Array.isArray(tracciabilitaDataIntro) && tracciabilitaDataIntro !== null) {
    } else {
        console.error('La lista non è disponibile o non è un array.');
        return null;
    }

    return (
        <>
            {tracciabilitaDataIntro && tracciabilitaDataIntro.map((data, index) => (
                <HomeSection
                    title={data.titolo}
                    imgSrcList={data.data}
                    putBackground={true}
                    descriptionList={data.dettagli.map(item => item.descrizione)}
                    backgroundColor={index % 2 === 0 ? 'white' : 'rgb(245,245,245)'}
                    flip={index % 2 === 0}
                />
            ))}
        </>
    );
};
import ItodoImage from "./itodo-img";
import { useRef, useState, useEffect } from "react";
import Loader from "../elements/loader";
import { Link } from 'react-router-dom';

const VideoBanner = ({ Title, Desc, Button, imgSrc, videoSrc, isVideo, href, ButtonThird, backgroundColor }) => {
    const videoElement = useRef(null);
    const imgElement = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const handleLoadedData = () => {
            setIsLoading(false);
        };

        if (isVideo && videoElement.current) {
            videoElement.current.addEventListener('loadeddata', handleLoadedData);
        } else if (!isVideo && imgElement.current) {
            if (imgElement.current.complete) {
                // Se l'immagine è già nella cache del browser
                setIsLoading(false);
            } else {
                imgElement.current.addEventListener('load', handleLoadedData);
                imgElement.current.addEventListener('error', handleLoadedData); // In caso di errore, nasconde il loader
            }
        }

        return () => {
            if (isVideo && videoElement.current) {
                videoElement.current.removeEventListener('loadeddata', handleLoadedData);
            }
            if (!isVideo && imgElement.current) {
                imgElement.current.removeEventListener('load', handleLoadedData);
                imgElement.current.removeEventListener('error', handleLoadedData);
            }
        };
    }, [isVideo, imgSrc, videoSrc]);

    return (
        <>
            {/*isLoading && <Loader />*/}
            <div className="chi-siamo-banner">
                {isVideo ? (
                    <video ref={videoElement} id="videoElement" autoPlay muted loop playsInline className="video-background">
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <ItodoImage ref={imgElement} src={imgSrc} />
                )}
                <div className="color-overlay" style={{ backgroundColor: backgroundColor }}></div>
                <div className="chi-siamo-banner-content">
                    <div className="chi-siamo-banner-content-title">
                        {Title}
                    </div>
                    <div className="chi-siamo-banner-content-desc">
                        {Desc}
                    </div>
                    <div className="chi-siamo-banner-content-btnwrapper">
                        <Link to={href}>
                            <button style={{ backgroundColor: ButtonThird ? 'rgb(238,53,42)' : '' }}>{Button}</button>
                        </Link>                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoBanner;


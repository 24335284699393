import { useTranslation } from "react-i18next";
import VideoBanner from "../../elements/videoBanner";
const PickingBanner = () => {

    const {t} = useTranslation("picking");
    return (
        <>
            <VideoBanner
                Title={t("banner.header")}
                Desc={t("banner.description")}
                Button={t("banner.btn_text")}
                imgSrc="/images/picking/pickingBanner.jpg"
                videoSrc="assets/images/picking/pickingVideo2.mp4"
                isVideo={true}
                href="#pickingprequel"
                ButtonThird={true}
                backgroundColor="rgba(2, 40, 255, 0.4)"
            />
        </>
    );
};

export default PickingBanner;
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

function SectionCounter() {

    const {t} = useTranslation("masp_caratteristiche");

    return (
        <>
            <div className="counter-blocks zindex1000">
                <div className="width100 row when-item-four zindex1000">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 zindex1000">
                        <div className="sx-count zindex1000 ">
                            <h2 className="sx-title zindex1000">
                                <span className="sx-cont-before zindex1000">+</span>
                                <span className="counter zindex1000">
                                    <CountUp end={t("counter.first_number")} duration={0} />
                                </span>
                                <span className="sx-cont-after zindex1000"></span>
                            </h2>
                            <div className="sx-count-info zindex1000">{t("counter.first_text")}</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 zindex1000">
                        <div className="sx-count">
                            <h2 className="sx-title">
                                <span className="sx-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={t("counter.second_number")} duration={0} />
                                </span>
                                <span className="sx-cont-after"></span>
                            </h2>
                            <div className="sx-count-info">{t("counter.second_text")}</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 zindex1000">
                        <div className="sx-count">
                            <h2 className="sx-title">
                                <span className="sx-cont-before">-</span>
                                <span className="counter">
                                    <CountUp end={t("counter.third_number")} duration={0} />%
                                </span>
                            </h2>
                            <div className="sx-count-info">{t("counter.third_text")}</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 zindex1000">
                        <div className="sx-count"> 
                            <h2 className="sx-title">
                                <span className="sx-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={t("counter.forth_number")} duration={0} />%
                                </span>
                            </h2>
                            <div className="sx-count-info">{t("counter.forth_text")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionCounter;
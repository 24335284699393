import { useTranslation } from 'react-i18next';
import { FaNetworkWired } from "react-icons/fa";
import styled from 'styled-components';

export default function PickingIntegration() {

    const { t } = useTranslation("picking");

    const MainDiv = styled.div`
    width: 100%;
    background-color: rgb(248, 248, 248);
    z-index: 1001;
    padding-bottom: 70px;
    padding-top:70px;
`;

    return (
        <>
            <MainDiv>
                <section className="integration-section">
                    <h2 className="integration-title">{t("integration.main_title")}</h2>
                    <div className='width100 perflex'>
                        <p className="integration-desc width80 perflex">
                            {t("integration.main_desc")}
                        </p>
                    </div>

                    <div className="integration-container">
                        <div className="integration-card">
                            {/*<FaSyncAlt className="integration-icon" />*/}
                            <img src="assets/images/logohead_white.png" className="integration-img" alt="MASP"></img>
                            <h3>{t("integration.first_title")}</h3>
                            <p>{t("integration.first_desc")}</p>
                            <ul>
                                <li>✔️ {t("integration.first_first_li")}</li>
                                <li>✔️ {t("integration.first_second_li")}</li>
                                <li>✔️ {t("integration.first_third_li")}</li>
                            </ul>
                        </div>

                        {/* Integrazione con altri sistemi */}
                        <div className="integration-card">
                            <FaNetworkWired className="integration-icon" />
                            <h3>{t("integration.second_title")}</h3>
                            <p>{t("integration.second_desc")}</p>
                            <ul>
                                <li>✔️ {t("integration.second_first_li")}</li>
                                <li>✔️ {t("integration.second_second_li")}</li>
                                <li>✔️ {t("integration.second_third_li")}</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </MainDiv>
        </>
    );
};
import { useTranslation } from 'react-i18next';
import NewHeaderTitle from "../../elements/newHeaderTitle";
import BenefitCard from '../../elements/BenefitCard';

const PickingPrequel = () => {

  const { t } = useTranslation("picking");

  const getIncrementalDescriptions = (variabile) => {
    let descriptions = [];
    let i = 1;
    let desc;

    while ((desc = t(`${variabile}${i}`)) && desc !== `${variabile}${i}`) {
      descriptions.push(desc);
      i++;
    }

    return descriptions;
  };

  const descriptions = getIncrementalDescriptions("prequel.desc_");

  const DataList = t('prequel.advantages.li_data', { returnObjects: true });

  if (!Array.isArray(DataList)) {
    console.error("Expected 'DataList' to be an array but got:", DataList);
    return null;
  }


  return (
    <>
      <NewHeaderTitle
        title={t("prequel.header")}
        descList={descriptions}
        buttonText={t("prequel.btn_text")}
        imgSrc="assets/images/picking/pickingrovesciato.png"
        imgSrcRidimensionabile="assets/images/picking/pickingrovesciato.png"
        imgSrcRidimensionabileBackground="assets/images/picking/pickingrovesciato.png"
        eventualHref="pickingprequel"
      />
      <div className="width100 perflex bg-title">
        <div className="width80 perflex">
          <BenefitCard title={t("prequel.advantages.header")} dataList={DataList}></BenefitCard>
        </div>
      </div>

    </>
  );
};

export default PickingPrequel;

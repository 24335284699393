import { useTranslation } from 'react-i18next';
import CardViewer from '../../elements/cardviewer';
import { FaIndustry, FaEye } from "react-icons/fa";
import { RxSpaceBetweenVertically } from "react-icons/rx";

export default function PickingApplications() {

    const { t } = useTranslation("picking");

    const cardData = [
        {
            icon: "flaticon-data",
            title: t("applications.first_title"),
            desc: t("applications.first_desc"),
            link: "/supervisor",
        },
        {
            icon: "flaticon-monitor-1",
            title: t("applications.second_title"),
            desc: t("applications.second_desc"),
            link: "/supervisor",
        },
        {
            icon: "flaticon-technical-support",
            title: t("applications.third_title"),
            desc: t("applications.third_desc"),
            link: "/supervisor",
        },
    ]

    const cardDataIcons = [
        {
            iconFa: <FaIndustry />,
        },
        {
            iconFa: <FaEye />,
        },
        {
            iconFa: <RxSpaceBetweenVertically />,
        }
    ];

    const combinedCardData = cardData.map((card, index) => {
        return {
            ...card,
            iconFa: cardDataIcons[index]?.iconFa
        };
    });

    return (
        <>
            <div className='width100 bgwhite zindex1001'>
                <section className="integration-section perflex-column">
                    <h2 className="integration-title">{t("applications.main_title")}</h2>
                    <p className="integration-desc width70 perflex">
                        {t("applications.main_desc")}
                    </p>
                    <CardViewer cards={combinedCardData} isFa={true} />
                </section>
            </div>
        </>
    );
};
import { useTranslation } from 'react-i18next';
import ItodoImage from "../../elements/itodo-img"
import styled from 'styled-components';

export default function PickingVersioni() {

    const MainDiv = styled.div`
    padding-top: 120px;
    padding-bottom: 70px;
    width: 80%;
    display: flex;
    z-index: 1001;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }
`;

    const PickingLeft = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1100px) {
        width:100%;
        justify-content: flex-start;
    }
}`;

    const Pickingtitle = styled.div`
color: var(--primary);
font-size: 6ch;
font-weight: 600;
line-height: 1.1;
}`;

    const PickingRight = styled.div`
width: 75%;
display: flex;
flex-direction: column;

@media screen and (max-width: 1100px) {
    width:100%;
}

}`;

    const PickingRightUpper = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 100%;
}`;

    const PickingRightLower = styled.div`
display: flex;
gap:50px;
width: 100%;
justify-content: center;
padding-top: 30px;

@media screen and (max-width: 900px) {
    flex-direction:column;
}
}`;




    const { t } = useTranslation("picking");

    const handleScroll = (href) => {
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    function VersionCard({ title, href, btn_text, img_src }) {

        const PickingProduct = styled.div`
        height: 500px;
        width: 400px;
        position: relative;
        overflow: hidden;
        border-radius: 15px;
        background-color: var(--primary);

        @media screen and (max-width: 900px) {
            width:100%;
            height:240px;
        }
        }`;

        const PickingProductDesc = styled.div`
        background-color: red;
        position: absolute;
        height: 60px;
        width: 200px;
        display: flex;
        justify-content: last baseline;
        align-items: last baseline;
        top:-20px;
        right:-50px;
        font-size: 22px;
        background-color: var(--primary);
        color:rgb(240, 240, 240);
        padding:10px;
        border-radius: 20px;
        z-index: 999;
        }`;

        const PickingProductImg = styled.div`
        z-index: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100%;
        
        img{
            z-index: 998;
        }

        @media screen and (max-width: 900px) {
            img{
                max-width: 70%;
            }
        }

        }`;

        return (
            <>
                <PickingProduct>
                    <div className="banner-card-firstcircle" style={{ backgroundColor: "rgba(142, 142, 142, 0.3)", transition: 'background-color 1s' }}></div>
                    <div className="banner-card-secondcircle" style={{ backgroundColor: "rgba(238, 53, 42, 0.3)", transition: 'background-color 1s' }}></div>
                    <PickingProductDesc>
                        {title}
                    </PickingProductDesc>
                    <PickingProductImg>
                        <ItodoImage src={img_src} />
                    </PickingProductImg>
                    <button onClick={() => handleScroll(href)} id="button-third" className="picking_v zindex1001">{btn_text}</button>
                </PickingProduct>
            </>
        );
    }

    return (
        <>
            <div className='width100 perflex bggrey zindex1001'>
                <MainDiv>
                    <PickingLeft>
                        <Pickingtitle>
                            {t("versions.header")}
                        </Pickingtitle>
                    </PickingLeft>
                    <PickingRight>
                        <PickingRightUpper>
                            <div className="picking_versioni_desc">
                                {t("versions.description")}
                            </div>
                        </PickingRightUpper>
                        <PickingRightLower>
                            <VersionCard title="Pick To Light" href="picktolight" btn_text={t("versions.btn_text")} img_src="images/picking/pickinglight.png" />
                            <VersionCard title="Pick To Image" href="picktoimage" btn_text={t("versions.btn_text")} img_src="images/picking/pickingrovesciatoOLD.png" />
                        </PickingRightLower>
                    </PickingRight>
                </MainDiv>
            </div >
        </>
    );
};
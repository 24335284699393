import PickingApplications from "./picking_applications";
import PickingBanner from "./picking_banner";
import PickingFaqs from "./picking_faqs";
import PickingIntegration from "./picking_integration";
import PickingPrequel from "./picking_prequel";
import PickingRadial from "./picking_radial";
import PickingVersioni from "./picking_versioni";
import PickingVersioniDesc from "./picking_versioni_desc";
import HalfBanner from "../../elements/HalfBanner"
import { useTranslation } from 'react-i18next';

export default function PickingHomePage() {

    const { t } = useTranslation("picking");

    return (
        <>
            <div className="padding-bottom70px">
                {true && <PickingBanner />}
                {true && <PickingPrequel />}
                {true && <PickingRadial />}
                {true && <HalfBanner
                    imgSrc={t("halfbanner.imgSrc")}
                    titleWhite={t("halfbanner.titleWhite")}
                    titleThird={t("halfbanner.titleThird")}
                    desc={t("halfbanner.desc")}
                    btnText={t("halfbanner.btnText")}
                    btnHref={t("halfbanner.btnHref")}
                />}
                {true && <PickingIntegration />}     
                {true && <PickingFaqs />}
                {true && <PickingVersioni />}
                {true && <PickingVersioniDesc />}
                {true && <PickingApplications />}
            </div>
        </>
    );
};
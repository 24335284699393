import React from 'react';
import FaqCard from "../../elements/FaqCard"
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PickingFaqs = () => {

    const {t} = useTranslation("picking");

    const faqCards = t('faq.data', { returnObjects: true });

    if (!Array.isArray(faqCards)) {
        console.error("Expected 'DataList' to be an array but got:", faqCards);
        return null;
    }

    return (
        <div className="faq-card-maindiv padding-bottom70px">
            {faqCards.map((card,index) => (
                <FaqCard wowDelay={card.wowDelay} title={card.title} desc={card.desc}></FaqCard>
            ))}
            <NavLink to="/contact-us"><button id='button-third'>{t("faq.btn")}</button></NavLink>
        </div>
    );
};

export default PickingFaqs;